import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Link from 'gatsby-link';
import React from 'react';
import { graphql } from 'gatsby';

const MOBILE = '@media(max-width: 480px)';
const TABLET = '@media(max-width: 800px)';

const hideOnMobile = {
  [MOBILE]: {
    display: 'none',
  },
};

const row = {
  display: 'flex',
  borderBottom: '1px solid #eee',
  padding: '5px 0',
};

const definition = {
  flex: 1,
  ...hideOnMobile,
};

const term = {
  flex: 3,
};

const IndexPage = ({ data }) => {
  const image = getImage(data.file.heroImage);
  return (
    <Layout>
      <div>
        <div
          css={{ width: 'auto', [MOBILE]: { margin: -30, marginBottom: 10 } }}
        >
          <GatsbyImage image={image} alt="Hero" />
        </div>
        <h2 css={hideOnMobile}>Velkommen til Oldtimer Service</h2>
        <p>
          Oldtimer Service er landets eneste spesialverksted for klassiske
          Mercedes-Benz. Vi tilbyr et <Link to="/om">bredt spekter</Link> av
          tjenester for utvalgte modeller fra 60- og 70-tallet.
        </p>

        <br />

        <h3 css={{ margin: '10px 0', fontWeight: 400 }}>Kontaktinformasjon</h3>
        <div
          css={{
            display: 'flex',
            [TABLET]: {
              display: 'block',
            },
          }}
        >
          <div css={{ flex: 1 }}>
            <div css={row}>
              <span css={definition}>Telefon</span>
              <span css={term}>+47 900 35 828</span>
            </div>

            <div css={row}>
              <span css={definition}>E-post</span>
              <span css={term}>
                <a href="mailto:bjarne@oldtimerservice.no">
                  bjarne@oldtimerservice.no
                </a>
              </span>
            </div>
            <br />
            <em>
              Telefonen er kun betjent i korte tidsrom og du bes henvende deg
              via enten e-post eller SMS.
            </em>
          </div>

          <div css={{ flex: 1 }}>
            <iframe
              title="Google maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1993.950459007894!2d11.029969016473574!3d60.01589598190431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46417bc2bc85a965%3A0x7818dab8b7bd4779!2sGjerdrumsveien+38%2C+2020+Skedsmokorset!5e0!3m2!1sen!2sno!4v1519853511074"
              css={{
                width: '100%',
                height: '300px',
                marginLeft: 20,
                [TABLET]: {
                  marginTop: 30,
                  marginLeft: 0,
                },
              }}
              frameBorder="0"
            />
          </div>
        </div>
        <iframe
          title="Link to Facebook"
          src="//www.facebook.com/plugins/like.php?href=http%3A%2F%2Fwww.facebook.com%2Fpages%2FOldtimer-Service%2F159981100696148%3Ffref%3Dts&amp;send=false&amp;layout=standard&amp;width=450&amp;show_faces=false&amp;font=arial&amp;colorscheme=light&amp;action=like&amp;height=35&amp;appId=214448022253"
          scrolling="no"
          frameBorder="0"
          style={{
            border: 'none',
            overflow: 'hidden',
            maxWidth: '100%',
            height: '40px',
            marginTop: 50,
          }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    file(relativePath: { eq: "hero.png" }) {
      heroImage: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`;

export default IndexPage;
